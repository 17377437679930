import { CheckIscoActionTypes } from "./constants";

const INIT_STATE = {
  iscoCodeList: [],
};

type ISCO_CODE_LIST = {
  code_id: string;
  name: string;
};

type CheckIscoActionType = {
  type:
    | CheckIscoActionTypes.API_RESPONSE_SUCCESS
    | CheckIscoActionTypes.API_RESPONSE_ERROR
    | CheckIscoActionTypes.API_FETCH_ISCO_CODE_LIST
  payload: {
    actionType?: string;
    data?: ISCO_CODE_LIST[];
    error?: string;
  };
};


type State = {
  iscoCodeList: any[];
};

const CheckIsco = (
  state: State = INIT_STATE,
  action: CheckIscoActionType
) => {
  switch (action.type) {
    case CheckIscoActionTypes.API_RESPONSE_SUCCESS: {
      switch (action.payload.actionType) {
        case CheckIscoActionTypes.API_FETCH_ISCO_CODE_LIST: {
          return {
            ...state,
            iscoCodeList: action.payload.data,
            loading: false,
          };
        }
        default:
          return { ...state };
      }
    }
    case CheckIscoActionTypes.API_RESPONSE_ERROR: {
      switch (action.payload.actionType) {
        case CheckIscoActionTypes.API_FETCH_ISCO_CODE_LIST: {
          return {
            ...state,
            iscoCodeList: [],
            error: action.payload.error,
            loading: false,
          };
        }
        default:
          return { ...state };
      }
    }

    case CheckIscoActionTypes.API_FETCH_ISCO_CODE_LIST: {
      return {
        ...state,
        iscoCodeList: [],
        loading: true
      };
    }

    default:
      return { ...state };
  }
};

export default CheckIsco;