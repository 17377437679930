import { CheckIscoActionTypes } from "./constants";

export type CheckIscoActionType = {
  type:
    | CheckIscoActionTypes.API_RESPONSE_SUCCESS
    | CheckIscoActionTypes.API_RESPONSE_ERROR
    | CheckIscoActionTypes.API_FETCH_ISCO_CODE_LIST
  payload: {} | string;
};

// common success
export const CheckIscoApiResponseSuccess = (
  actionType: string,
  data: any
): CheckIscoActionType => ({
  type: CheckIscoActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const CheckIscoApiResponseError = (
  actionType: string,
  error: any
): CheckIscoActionType => ({
  type: CheckIscoActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const fetchIscoCodeList = (lang: string): CheckIscoActionType => ({
  type: CheckIscoActionTypes.API_FETCH_ISCO_CODE_LIST,
  payload: { lang },
});