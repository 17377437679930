import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpBackend from "i18next-http-backend";

import translationEs from 'locales/es/translation.json';
import translationRu from 'locales/ru/translation.json';
import translationDe from 'locales/de/translation.json';
import translationIt from 'locales/it/translation.json';

import {getLocalStorageLanguage} from "utils/common";


// translations
const resources = {
    es: {
        translation: translationEs,
    },
    de: {
        translation: translationDe,
    },
    it: {
        translation: translationIt,
    },
    ru: {
        translation: translationRu,
    },
};

const language = getLocalStorageLanguage();

const apiKey = "LcGLmPuKw1ejcANBJgykQg";
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18n.use(detector)
    .use(HttpBackend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: { order: ["path", "navigator"] },

        fallbackLng: language.code, // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        ns: ["default"],
        defaultNS: "default",

        supportedLngs: ["en","es","fr","de","ja","th","kk","ru"],

        backend: {
            loadPath: loadPath
        }


    });

i18n.changeLanguage(language.code);

//console.log("language", localStorage.getItem('i18nextLng'));

export default i18n;
