import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  fetchAllISCOCodes,
} from "helpers";
import { CheckIscoApiResponseError, CheckIscoApiResponseSuccess } from "./actions";
import { CheckIscoActionTypes } from "./constants";

type ISCOData = {
  payload: {
    lang: string;
  };
  type: string;
};

function* fetchIscoCodeList({
  payload: { lang },
  type,
}: ISCOData): SagaIterator {
  try {
    const response = yield call(fetchAllISCOCodes, lang);
    const data = response.data.isco_list;
    yield put(CheckIscoApiResponseSuccess(CheckIscoActionTypes.API_FETCH_ISCO_CODE_LIST, data));
  } catch (error: any) {
    console.log({error})
    yield put(CheckIscoApiResponseError(CheckIscoActionTypes.API_FETCH_ISCO_CODE_LIST, error));
  }
}

export function* watchISCOCodeList() {
  yield takeEvery(CheckIscoActionTypes.API_FETCH_ISCO_CODE_LIST, fetchIscoCodeList);
}

function* checkIscoSaga() {
  yield all([
    fork(watchISCOCodeList),
  ]);
}

export default checkIscoSaga;