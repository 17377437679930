import { Language } from "../layouts/types";
import enFlag from "../layouts/Topbar/LanguageDropdown/flags/au.jpg";
import germanyFlag from "../layouts/Topbar/LanguageDropdown/flags/germany.jpg";
import spainFlag from "../layouts/Topbar/LanguageDropdown/flags/spain.jpg";
import russiaFlag from "../layouts/Topbar/LanguageDropdown/flags/russia.jpg";
import frenchFlag from '../layouts/Topbar/LanguageDropdown/flags/french.jpg';
import japanFlag from '../layouts/Topbar/LanguageDropdown/flags/japan.png';
import thaiFlag from '../layouts/Topbar/LanguageDropdown/flags/thai.png';
import kazakhFlag from '../layouts/Topbar/LanguageDropdown/flags/kazakh.png';
import { useTranslation } from "react-i18next";

/**
 * Get User Details from Session Storage
 * @returns
 */
const getUserDetailsFromSessionStorage = () => {
  const user = JSON.parse(sessionStorage.getItem("hyper_user")!);
  return user;
};

const getLocalStorageShowMenu = () => {
  return localStorage.getItem("showMenu") &&
    localStorage.getItem("showMenu") === "true"
    ? true
    : false;
};

const Languages: Language[] = [
  {
    code: "en",
    name: "English",
    flag: enFlag,
  },
  {
    code: "de",
    name: "German",
    flag: germanyFlag,
  },
  {
    code: "es",
    name: "Spanish",
    flag: spainFlag,
  },
  {
    code: "fr",
    name: "French",
    flag: frenchFlag,
  },
  {
    code: "ja",
    name: "Japanese",
    flag: japanFlag,
  },
  {
    code: "kk",
    name: "Kazakh",
    flag: kazakhFlag,
  },
  {
    code: "ru",
    name: "Russian",
    flag: russiaFlag,
  },
  {
    code: "th",
    name: "Thai",
    flag: thaiFlag,
  },
];

const getLocalStorageLanguage = () => {
  let i18Lng = localStorage.getItem("i18nextLng") || "en";
  let code = localStorage.getItem("languageCode") || i18Lng;
  let currLang: Language =
    Languages.find(function (item) {
      return item.code == code;
    }) || Languages[0];
  let name = localStorage.getItem("languageName") || currLang.name;
  return { code: code, name: name };
};

export {
  getUserDetailsFromSessionStorage,
  getLocalStorageShowMenu,
  getLocalStorageLanguage,
  Languages,
};

export const errorToast = {
  toastId: "error",
  style: {
    background: "#FEE2E2",
  },
};

export const successToast = {
  toastId: "success",
  style: {
    background: "#fff",
  },
};
