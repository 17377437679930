import Routes from "routes/Routes";
import moment from "moment-timezone";

// For Default import Saas.scss
import "assets/scss/Saas.scss";
// import 'assets/scss/Creative.scss';
// import 'assets/scss/Modern.scss';

const App = () => {
  moment.tz("Asia/Calcutta");
  return <Routes />;
};

export default App;
