import { APICore } from "./apiCore";

const api = new APICore();

function updateUserDetailsApi(params: {
  first_name: string;
  last_name: string;
  bio: string;
  email: string;
  password: string;
  org: string;
  website: string;
}) {
  const baseUrl = "/api/users/edit";
  return api.create(`${baseUrl}`, params);
}

export { updateUserDetailsApi };
