import { BrowserRouter } from "react-router-dom";
import { AllRoutes } from "./index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const Routes = () => {
  return (
    <BrowserRouter>
      <AllRoutes />
      <ToastContainer
        limit={3}
        position="top-right"
        autoClose={3500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="light"
      />
    </BrowserRouter>
  );
};

export default Routes;
