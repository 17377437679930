import { APICore } from "./apiCore";

const api = new APICore();

function login(params: { email: string; password: string }) {
  const baseUrl = "/api/users/login";
  return api.create(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = "/api/users/logout";
  return api.create(`${baseUrl}`, {});
}

function signup(params: {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}) {
  const baseUrl = "/api/users/register";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
  const baseUrl = "/password/reset/confirm/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm };
